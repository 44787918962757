.header__branding, .footer {
  background-color: #4D4D4D;
}

.header__navbar, .footer__navbar {
  background-color: #292929;
}

.is-sticky .header__navbar {
  background-color: #292929;
}

.is-sticky .header__navbar .header__fourth .region-header-fourth .main-menu__wrapper .sticky-right .sticky-signin {
  background-color: #405866;
  color: #fff;
  padding: 6px 20px;
  text-decoration: none !important;
}

h1 {
  color: #030303;
}
